<script>
import AdoptionsService from "@/services/adoptions.service";

export default {
  name: "AdoptionReview",
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data:  () => ({
    search: '',
    selectedClients: [],
    status: 1,
    loading: false,
    acceptLoading: false,
    rejectLoading: false,
    headers: [
      {
        text: 'Client',
        filterable: true,
        value: 'trade_name'
      },
      {
        text: 'EIN',
        filterable: true,
        value: 'ein'
      },
      {
        text: 'Legal Name',
        filterable: true,
        value: 'legal_name'
      }
    ],
    clients: []
  }),
  methods: {
    handleRowClick(item) {
      this.$router.push(`/clients/${item.id}`)
    },
    async getData() {
      this.loading = true

      try {
        this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status)
      } finally {
        this.loading = false
      }
    },
    async handleReject() {
      this.rejectLoading = true;
      for (let i = 0; i < this.selectedClients.length; i++) {
        let client = this.selectedClients[i]
        await AdoptionsService.deleteAdoption(client.id)
      }

      this.rejectLoading = false;
      await this.getData()

    },
    async handleAccept() {
      const payload = {
        status: 2
      }
      this.acceptLoading = true;

      for (let i = 0; i < this.selectedClients.length; i++) {
        let client = this.selectedClients[i]
        await AdoptionsService.patchAdoption(client.adoption_id, payload)
      }
      this.acceptLoading = false
      await this.getData()
    }
  },
  async mounted() {
    this.getData()
  },
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              @click:row="handleRowClick"
              :items="clients"
              :loading="loading"
              :search="search"
              show-select
              v-model="selectedClients"
              disable-pagination
              hide-default-footer
            />
          </v-card-text>
          <v-card-actions>
            <v-btn @click="handleAccept" :loading="acceptLoading" :disabled="!selectedClients.length" color="primary">Accept</v-btn>
            <v-btn @click="handleReject" :loading="rejectLoading" :disabled="!selectedClients.length" color="primary">Reject</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>