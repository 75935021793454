<script>
import AdoptionsService from "@/services/adoptions.service";
import ClientsService from "@/services/clients.service";

export default {
  name: "RefileNeeded",
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    moveToDenialLoading: false,
    moveToAwaitingLoading: false,
    clients: [],
    headers: [
      {
        text: 'Client',
        value: 'trade_name',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      }
    ],
    selectedClients: [],
    selectedQuarter: 1,
    status: 2,
    search: '',
  }),
  methods: {
    async getData() {
      this.loading = true

      try {
        this.clients = await AdoptionsService.getAdoptionsQuarter(this.entityId, this.selectedQuarter, this.status)
      } finally {
        this.loading = false
      }
    },
    async moveToAwaiting() {
      const payload = {
        status: 1
      }
      this.moveToAwaitingLoading = true

      try {
        for (let i = 0; i < this.selectedClients.length; i++) {
          let client = this.selectedClients[i]
          await ClientsService.patchAdoptionQuarters(client.quarter_id, payload)
        }
        this.selectedClients = []
        await this.getData()
      } finally {
        this.moveToAwaitingLoading = false
      }
    },
    async moveToDenialReceived() {
      const payload = {
        status: 3
      }
      this.moveToDenialLoading = true

      try {
        for (let i = 0; i < this.selectedClients.length; i++) {
          let client = this.selectedClients[i]
          await ClientsService.patchAdoptionQuarters(client.quarter_id, payload)
        }

        this.selectedClients = []
        await this.getData()
      } finally {
        this.moveToDenialLoading = false
      }
    },

  },
  watch: {
    selectedQuarter() {
      this.getData()
    }
  },
  mounted() {
    this.getData()
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-title style="width: 100%">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-radio-group v-model="selectedQuarter" row >
                  <v-radio :value="1" label="Q1" />
                  <v-spacer />
                  <v-radio :value="2" label="Q2"/>
                  <v-spacer />
                  <v-radio :value="3" label="Q3"/>
                  <v-spacer />
                  <v-radio :value="4" label="Q4"/>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              :search="search"
              show-select
              :loading="loading"
              v-model="selectedClients"
              disable-pagination
              hide-default-footer
            />
          </v-card-text>
          <v-card-actions>
            <v-row justify="space-between">
              <v-col cols="auto">
                <v-btn color="primary" @click="moveToAwaiting" :loading="moveToAwaitingLoading" :disabled="!selectedClients.length">Move to awaiting IRS payments</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click="moveToDenialReceived" :loading="moveToDenialLoading" :disabled="!selectedClients.length">Move to denial received</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>