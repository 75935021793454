<script>
import ClientHeader from "@/components/Client/ClientHeader.vue";
import ClientService from "@/services/clients.service";

export default {
  name: "Emails",
  components: { ClientHeader },
  data: () => ({
    client: null,
    generating: 5, // 1 - intro, 2 - unresponsive 1st, 3 - unresponsive 2nd, 4 - unresponsive 3rd,
    sendLoading: false,
    clientId: "",
    adopterId: undefined,
    body: "",
    emailSent: false,
    CSAUploaded: false,
    sentEmails: [],
    emailsLoading: false,
    loading: true,
    headers: [
      {
        text: "Client",
        value: "trade_name",
        filterable: true,
      },
      {
        text: "EIN",
        value: "ein",
        filterable: true,
      },
      {
        text: "Type of Email",
        value: "status_name",
        filterable: true,
      },
      {
        text: "Date",
        value: "date",
      },
    ],
  }),
  methods: {
    handleGoBack() {
      this.$router.push(`/post-adopt-report/${this.adopterId}#intro-email`);
    },
    async getOutstanding() {
      const res = await ClientService.getUploadReqDocs(this.clientId);
      const csa = res.find(doc => doc.document_name === "Client services agreement"); // CSA

      this.CSAUploaded = !!csa.document_status;
    },
    async getCurrentClient() {
      this.client = await ClientService.getOneClient(this.clientId);
    },

    getTemplate() {
      switch (this.generating) {
        case 1:
          this.body = "<p>Hello [FIRST_NAME] [LAST_NAME],</p> <p>E-mail template to introduce the new Managing firm to the client.</p>";
      }
    },
    async getSentEmails() {
      this.emailsLoading = true;
      if (!this.adopterId) {
        return;
      }

      try {
        this.sentEmails = await ClientService.getSentEmails(this.adopterId);
      } finally {
        this.emailsLoading = false;
      }
    },
    async send() {
      this.sendLoading = true;

      try {
        const payload = {
          type: this.generating,
          body: this.body,
        };

        await ClientService.sendAdoptionEmail(this.clientId, payload);
        this.$store.dispatch("snackbar/showSnackbarMessage", {
          message: "Email sent successfully!",
          duration: 4000,
          mode: "success",
        });
        this.emailSent = true;
        this.getSentEmails();
      } finally {
        this.sendLoading = false;
      }
    },
  },
  async mounted() {
    this.clientId = this.$route.params.clientId;
    this.adopterId = this.$route.query.adopterId;
    await this.getCurrentClient();
    await this.getOutstanding();
    this.loading = false;
    this.getSentEmails();
  },
  watch: {
    generating() {
      this.getTemplate();
    },
  },
};
</script>

<template>
  <div>
    <ClientHeader :client="client" />
    <v-row v-if="loading">
      <v-col>
        <v-progress-circular
          :size="30"
          :width="3"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-container v-else>
      <v-row>
        <v-col>
          <v-alert type="success" dense v-if="emailSent && adopterId">
            Email sent successfully! Go back to <a :href="`/post-adopt-report/${adopterId}`">List of clients</a>.
          </v-alert>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="auto">
          <v-btn color="primary" @click="generating = 1">Generate Introduction</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" @click="generating = 2">Generate Unresponsive 1st</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary">Generate Unresponsive 2nd</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary">Generate Unresponsive 3rd</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card v-if="generating === 1">
            <v-card-title>
              Introduction Email
            </v-card-title>
            <v-card-text>
              <VueEditor v-model="body" />
              <br>
              <v-alert v-if="!CSAUploaded" type="error" dense>
                CSA document is not uploaded for client, upload it from
                <a :href="`/clients/${clientId}/documents`">documents</a>
                tab.
              </v-alert>
              <v-alert v-else type="success" dense>
                CSA document is uploaded for client.
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-row justify="end">
                <v-col cols="auto">
                  <v-btn @click="send" :disabled="!CSAUploaded" :loading="sendLoading" color="primary">
                    Send
                    <v-icon right>mdi-send</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="adopterId">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="sentEmails"
                :loading="emailsLoading"
                disable-pagination
                hide-default-footer
                item-key="id"
              >
                <template #item.date="{ item }">
                    {{ new Date(new Date().setDate(new Date().getDate() - item.days_in_status)).toLocaleDateString() }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-btn @click="handleGoBack" color="primary"><v-icon>mdi-arrow-left</v-icon> Go back to emails report</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>

</style>