<script>
import AdoptionsService from "@/services/adoptions.service";

export default {
  name: "UnresponsiveClients",
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    status: 7,
    clients: [],
    headers: [
      {
        text: 'Client',
        value: 'client',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      },
      {
        text: 'Date contact initiated',
        value: 'date',
      }
    ],
    selectedClients: []
  }),
  methods: {
    confirmDocuments() {
      // TODO: pitay Velik ili Kal za api v 9:00 sutrinta

      this.$store.dispatch('snackbar/showSnackbarMessage',
        {message: 'Verification completed. Client moved to waiting Signed 8821', duration: 6000})
      this.selectedClients = []
    }
  },
  async mounted() {
    this.loading = true

    try {
      this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status)
    } finally {
      this.loading = false
    }
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              show-select
              v-model="selectedClients"
              disable-pagination
              hide-default-footer
              :loading="loading"
            />
          </v-card-text>
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
              <v-btn color="primary" @click="confirmDocuments" :disabled="!selectedClients.length">Contact confirmed</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>