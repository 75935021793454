<template>
  <div>
    <ClientHeader :client="client" />

    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row>
      <v-col cols="12" md="6">
        <ClientPackagesList :packages="type4506files" :loading="type4506Loading">
          <template #title>
            <div class="d-flex justify-space-between align-center">
              <span class="text-h4 ml-3">4506-T</span>
            </div>
          </template>
          <template #default="{ pkg,pkgIndex }">
            <span :class="{'pulse': pkgIndex === type4506files.length-1 }" class=" px-2 my-4">
              <a @click="download4506(pkg.id, pkg.url, pkg.client_legal_name)">
                <v-icon class="mr-2 ">mdi-download</v-icon> {{ pkg.client_legal_name }}.pdf
                <br/>
              {{ pkg.created }}
              </a>

            </span>
          </template>
          <template #empty-text>
            <p class="text-caption">NO 4506-T FILES FOUND</p>
          </template>
          <template #actions>
            <div
              v-if="!getPackagesInProgress"
              class="d-flex justify-end align-end mb-3 mr-2"
            >
              <v-spacer></v-spacer>
              <div class="d-flex align-center">
                <v-btn
                  id="generatePackageBtn"
                  color="primary"
                  @click.native="generate4506"
                  :loading="generation4506inProgress"
                >
                  GENERATE FILE
                </v-btn>
              </div>
            </div>
          </template>
        </ClientPackagesList>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" md="6">
        <v-list-item class="bordered" three-line>
          <v-card
              @drop.prevent="drop($event)"
              @dragover.prevent="dragoverData = true"
              @dragenter.prevent="dragoverData = true"
              @dragleave.prevent="dragoverData = false"
              width="1000"
          >
            <v-card-title>
              <v-row class="d-flex justify-center">
                <p class="text-center">Signed 4506-T Upload</p>
              </v-row>
            </v-card-title>
            <br>
            <v-card-text>
              <v-row class="d-flex justify-center align-center">
                <div
                    class="p-12"
                >
                  <input type="file" multiple name="fields[assetsFieldHandleUploads4506][]"
                         id="assetsFieldHandleUploads4506"
                         class="d-none absolute" @input="onChange" ref="file4506"
                  />

                  <label style="border: 3px dashed lightgray" for="assetsFieldHandleUploads4506"
                         class="block d-flex flex-column cursor-pointer px-0 px-md-10">
                    <v-icon style="font-size: 5rem">mdi-upload</v-icon>
                    <v-card-title>Drag & drop files or&nbsp; <span style="cursor: pointer" class="text-decoration-underline">Browse</span> </v-card-title>
                  </label>
                  <br>
                </div>
              </v-row>
              <br>
            </v-card-text>
          </v-card>
        </v-list-item>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" md="6">
        <v-list-item class="bordered" three-line>
          <v-card
              @drop.prevent="drop2($event)"
              @dragover.prevent="dragoverData = true"
              @dragenter.prevent="dragoverData = true"
              @dragleave.prevent="dragoverData = false"
              width="1000"
          >
            <v-card-title>
              <v-row class="d-flex justify-center">
                <p class="text-center">Transcript Upload</p>
              </v-row>
            </v-card-title>
            <br>
            <v-card-text>
              <v-row class="d-flex justify-center align-center">
                <div
                    class="p-12"
                >
                  <input type="file" multiple name="fields[assetsFieldHandleUploadsTranscript][]"
                         id="assetsFieldHandleUploadsTranscript"
                         class="d-none absolute" @input="onChange2" ref="fileTranscript"
                  />

                  <label style="border: 3px dashed lightgray" for="assetsFieldHandleUploadsTranscript"
                         class="block d-flex flex-column cursor-pointer px-0 px-md-10">
                    <v-icon style="font-size: 5rem">mdi-upload</v-icon>
                    <v-card-title>Drag & drop files or&nbsp; <span style="cursor: pointer" class="text-decoration-underline">Browse</span> </v-card-title>
                  </label>
                  <br>
                </div>
              </v-row>
              <br>
            </v-card-text>
          </v-card>
        </v-list-item>
      </v-col>
    </v-row>


    <v-row>
      <v-col>
        <span class="text-h5">Transcript request history</span>
      </v-col>
    </v-row>
    <v-row v-if="tasksLoading">
      <v-col cols="4"><v-skeleton-loader type="card" /></v-col>
      <v-col cols="8"><v-skeleton-loader type="card" /></v-col>
    </v-row>
    <v-row v-else-if="!tasksLoading && transcriptQueue?.tasks?.length" class="fill-height">
      <v-col cols="4" class="d-flex flex-column" style="gap: 10px">
        <div @click="selectedTaskIdx = i" v-for="(task, i) in transcriptQueue.tasks" :key="task.id">
          <TranscriptQueueTask
            :class="{ selected: transcriptQueue.tasks[selectedTaskIdx].id === task.id }"
            :task="task"
            :index="i"
            :loading="loadingTask[i]"
          >
            <template #actions>
              <v-list dense>
                <div v-for="status in statuses" :key="status.id">
                  <v-list-item @click="changeStatus(task.id, status.id, i)">{{
                    status.name
                  }}</v-list-item>
                  <v-divider />
                </div>
              </v-list>
            </template>
          </TranscriptQueueTask>
        </div>
      </v-col>
      <v-col cols="8">
        <TranscriptQueueTaskDetails :task="transcriptQueue.tasks[selectedTaskIdx]" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="4">
        <v-alert colored-border elevation="2" type="info"> There are no tasks currently! </v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ClientPackagesList from "@/components/Client/Profile/ClientPackagesList.vue";
import PackageService from "@/services/packages.service";
import ClientService from "@/services/clients.service";
import ClientHeader from "@/components/Client/ClientHeader.vue";
import TranscriptQueueTaskDetails from "@/components/TranscriptQueue/TranscriptQueueTaskDetails.vue";
import TranscriptQueueTask from "@/components/TranscriptQueue/TranscriptQueueTask.vue";
import TranscriptService from "@/services/transcript.service";
import AuthService from "@/services/auth.service";

export default {
  name: "Transcripts",
  components: { TranscriptQueueTask, TranscriptQueueTaskDetails, ClientHeader, ClientPackagesList },
  data: () => ({
    generationInProgress: false,
    deleteInProgress: false,
    getPackagesInProgress: false,
    isDraft: false,
    type2020supplements: [],
    type4506files: [],
    type4506Loading: false,
    generation4506inProgress: false,
    client: null,
    clientId: null,
    tasksLoading: false,
    transcriptQueue: {},
    selectedTaskIdx: 0,
    loadingTask: [],
    statuses: [],
    filesToUpload: [],
    loading: false,
    currentUser: null,
  }),
  methods: {
    drop(event) {
      event.preventDefault();
      this.$refs.file4506.files = event.dataTransfer.files;
      this.onChange();
    },
    onChange() {
      for (let i = 0; i < this.$refs.file4506.files.length; i++) {
        if (this.$refs.file4506.files[i].size < 25000000) {
          let uploadedFile = this.$refs.file4506.files[i];
          const newFile = {
            original_file_name: uploadedFile.name,
            url: uploadedFile,
            created_on: this.forDate,
            mime_type: uploadedFile.type,
            uploaded_by: this.currentUser.id,
            uploadedFile: uploadedFile,
            uploadingFiles: false,
          };
          if (uploadedFile) {
            this.$emit('fileDropedNotSaved', true);
            this.filesToUpload.push(newFile);
          }
        } else {
          this.showErrorMessage();
        }
      }

      this.saveData('4506');
    },
    drop2(event) {
      event.preventDefault();
      this.$refs.fileTranscript.files = event.dataTransfer.files;
      this.onChange2();
    },
    onChange2() {
      for (let i = 0; i < this.$refs.fileTranscript.files.length; i++) {
        if (this.$refs.fileTranscript.files[i].size < 25000000) {
          let uploadedFile = this.$refs.fileTranscript.files[i];
          const newFile = {
            original_file_name: uploadedFile.name,
            url: uploadedFile,
            created_on: this.forDate,
            mime_type: uploadedFile.type,
            uploaded_by: this.currentUser.id,
            uploadedFile: uploadedFile,
            uploadingFiles: false,
          };
          if (uploadedFile) {
            this.$emit('fileDropedNotSaved', true);
            this.filesToUpload.push(newFile);
          }
        } else {
          this.showErrorMessage();
        }
      }

      this.saveData('4506-transcript');
    },
    postItem(formData, index) {
      ClientService.uploadClientCSVFileWithReturningData(this.$route.params.clientId, formData)
          .then((res) => {
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: 'File uploaded successfully',
              duration: 4000,
              mode: 'success'
            });
            this.$emit('getUploads', true)
            this.filesToUpload = []
            this.loading = false
            this.$emit('closeUpload', true)
          }).catch(error => {
        this.$func.popMessage(`${error.response.data.error}`, 'fail')
        this.loading = false
      }).finally(() => {
        this.loading = false
        this.filesToUpload = []
      })
    },
    saveData(uploadType) {
      this.loading = true
      this.$emit('fileDropedNotSaved', false)
      this.filesToUpload.forEach((e, index) => {
        if (!e.id) {
          console.log(uploadType)
          let formData = new FormData();
          formData.append('uploaded_file', e.uploadedFile);
          formData.append('upload_type',  uploadType ? uploadType :'filedrop');
          this.postItem(formData, index)
        }
      })
    },
    get4506files() {
      this.type4506Loading = true;
      return ClientService.get4506Uploads(this.$route.params.clientId)
        .then((res) => {
          this.type4506files = [...res];
        })
        .finally(() => (this.type4506Loading = false));
    },
    before4506download(id) {
      const payload = {
        status: 2,
      };
      return ClientService.patch4506Report(id, payload);
    },
    async download4506(id, url, clientLegalName) {
      await this.before4506download(id);

      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = clientLegalName.replace(/\./g, "") + ".pdf";

        // Simulate click
        link.click();

        // Clean up
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    },
    generate4506() {
      this.generation4506inProgress = true;
      return ClientService.generate4506file(this.$route.params.clientId)
        .then((res) => {
          this.type4506files = res;
          this.get4506files();
        })
        .finally(() => (this.generation4506inProgress = false));
    },
    getCurrentClient() {
      return ClientService.getOneClient(this.clientId)
        .then((data) => {
          this.client = data;
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `${error.response.data.error}. Please, try again.`,
            duration: 4000,
            mode: "fail",
          });
        });
    },
    getTranscript() {
      return TranscriptService.getTranscriptQueueById(this.type4506files[0].queue_id).then((res) => {
        this.transcriptQueue = res;
      })
    },
    getStatuses(){
      return TranscriptService.getTranscriptStatuses().then(response => {
        this.statuses = response
      })
    },
    changeStatus(taskId, statusId, index){
      const statusModel = {
        status: statusId
      }
      this.loadingTask[index] = true
      this.loadingTask = [...this.loadingTask]
      return TranscriptService.patchTranscriptTaskStatus(taskId, statusModel).then((data) => {
        this.$func.popMessage("Status Changed Successfully.", "success")
        const { notes } = this.transcriptQueue.tasks[index]
        this.transcriptQueue.tasks[index] = {...data, notes }
      }).catch(err => {
        this.$func.popMessage("Changing Status Failed.", "error")
      }).finally(() => {
        this.loadingTask[index] = false
        this.loadingTask = [...this.loadingTask]
      })
    },
  },
  async created() {
    this.clientId = this.$route.params.clientId;
    this.getCurrentClient();
    const data = await AuthService.getCurrentUserFromCookie()
    this.currentUser = data.data
    this.tasksLoading = true
    await this.get4506files()
    if (!this.type4506files.length) {
      this.tasksLoading = false
    } else {
      await Promise.all([
        this.getTranscript(),
        this.getStatuses()
      ]).finally(() => this.tasksLoading = false)
    }
  },
};
</script>

<style scoped>
.pulse {
  border-radius: 20px;
  animation: pulse-animation 1.5s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>