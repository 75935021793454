<script>
import AdoptionsService from "@/services/adoptions.service";

export default {
  name: "WaitingSigned8821",
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    status: 11,
    clients: [],
    receivedLoading: false,
    headers: [
      {
        text: 'Client',
        value: 'client',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      },
      {
        text: 'Date contact initiated',
        value: 'date',
      }
    ],
    selectedClients: []
  }),
  methods: {
    async markReceived() {
      const payload = {
        status: 12
      }
      this.receivedLoading = true;

      for (let i = 0; i < this.selectedClients.length; i++) {
        let client = this.selectedClients[i]
        await AdoptionsService.patchAdoption(client.adoption_id, payload)
      }

      this.$store.dispatch('snackbar/showSnackbarMessage',
        {message: 'Moved to Waiting to be Filed', duration: 6000})
      this.receivedLoading = false;
      this.selectedClients = []
      await this.getData()

    },
    async getData() {
      this.loading = true

      try {
        this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status)
      } finally {
        this.loading = false
      }
    },
  },
  async mounted() {
    await this.getData()
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              show-select
              v-model="selectedClients"
              :loading="loading"
              disable-pagination
              hide-default-footer
            />
          </v-card-text>
          <v-card-actions>
            <div class="d-flex justify-center" style="width: 100%">
              <v-btn color="primary" :loading="receivedLoading" @click="markReceived" :disabled="!selectedClients.length">Signed 8821 received</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>