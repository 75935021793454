<template>
  <v-dialog width="500" v-model="showDialog">
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-card
        @drop.prevent="drop($event)"
        @dragover.prevent="dragoverData = true"
        @dragenter.prevent="dragoverData = true"
        @dragleave.prevent="dragoverData = false"
    >
      <v-card-title>
        <v-row>
          <v-col class="text-center">Upload</v-col>
        </v-row>
      </v-card-title>
      <br>
      <v-card-text>
        <v-row class="d-flex justify-center align-center">
          <div
              class="p-12"
          >
            <input type="file" multiple name="fields[assetsFieldHandleUploads][]"
                   id="assetsFieldHandleUploads"
                   class="d-none absolute" @input="onChange" ref="file"
            />

            <label style="border: 3px dashed lightgray" for="assetsFieldHandleUploads"
                   class="block d-flex flex-column cursor-pointer px-0 px-md-10">
              <v-icon style="font-size: 5rem">mdi-upload</v-icon>
              <v-card-title>Drag & drop files or&nbsp; <span style="cursor: pointer" class="text-decoration-underline">Browse</span> </v-card-title>
            </label>
            <br>
            <div v-if="$refs.file">
              <v-row class="px-3" v-for="(file,index) in $refs.file.files">
                  <v-col class="mt-1 py-1 d-flex flex-row justify-space-between" style="border: 1px solid #11AF22" cols="12">
                    <span
                      style="font-size: 12px"
                      :key="index + 999">{{ file.name }}
                    </span>
                    <v-btn x-small icon @click="removeFileFromFileList(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
              </v-row>
            </div>
          </div>
        </v-row>
        <br>
        <v-row
            v-for="type in uploadTypes" :key="type.value"
            dense
            align="center"
            justify="start"
            class="text--primary px-0 px-md-10"
        >
          <v-col cols="12">
            <v-switch hide-details v-model="uploadType" :value="type.value">
              <template #label>
                <span class="text--primary">I am uploading a {{type.label}}</span>
              </template>
            </v-switch>
          </v-col>
          <v-col cols="12" v-if="uploadType === type.value && packagesByType[type.value]">
            <v-select
              class="ma-auto"
              v-model="selectedPackage"
              :items="packagesByType[type.value]"
              item-text="label"
              return-object
            />
          </v-col>
          <v-col
            cols="12"
            v-else-if="uploadType === type.value && packageNeeded && !packagesByType[type.value]"
            >
              <v-alert class="ma-auto mt-2" outlined dense type="error">{{type.error}} You can't upload this type of file.</v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            id="save-files-btn"
            :disabled="!isActiveButton"
            color="primary"
            block
            v-if="filesToUpload.length > 0"
            @click="saveData"
        >
          Upload files
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
        v-model="errorMessage"
        width="500"
    >
      <v-card class="pa-16" style="background-color: #263B4F">
        <h2 style="color: #FFFFFF" class="text-center mb-7">The chosen file is over the File Size limit of 25Mb.
          Please
          try another file.</h2>
        <v-progress-linear absolute color="red"
                           :v-model="errProgressBarValue"
                           :buffer-value="bufferValue">
        </v-progress-linear>
      </v-card>


    </v-dialog>
  </v-dialog>
</template>


<script>
import PackagesService from "@/services/packages.service";
import ClientService from "@/services/clients.service";

export default {
  name: "UploadFile",
  data: () => ({
    loading: false,
    dragoverData: false,
    isActiveButton: true,
    menuOpened: false,
    dropdownReqDocuments: [],
    files: [],
    errorMessage: false,
    errProgressBarValue: 0,
    bufferValue: 0,
    packages: [],
    filesToUpload: [],
    forDate: new Date(),
    clientID:'',
    selectedPackage: "",
    packagesByType: {},
    uploadType: "",
    uploadTypes: [
      {
        value: 'submission',
        label: 'signed Final Report',
        error: 'No Final package found for this client!',
      },
      {
        value: '2020-supplement',
        label: 'signed 2020 Supplement',
        error: 'No 2020 Supplement package found for this client!'
      },
      // {
      //   value: '4506',
      //   label: '4506-Т'
      // },
      // {
      //   value: '4506-transcript',
      //   label: 'transcript'
      // }
    ]
  }),
  props: {
    client:{
      type: String
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    currentUser: {
      type: Object,
      default: () => {
      }
    },
  },
  methods: {
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
    },
    saveData() {
      this.loading = true
      this.isActiveButton = false
      this.$emit('fileDropedNotSaved', false)
      this.filesToUpload.forEach((e, index) => {
        if (!e.id) {
          let formData = new FormData();
          formData.append('uploaded_file', e.uploadedFile);
          formData.append('upload_type',  this.uploadType ? this.uploadType :'filedrop');
          // this.awaiting_review[index].uploadingFiles = true;
          // this.$emit('pushToAwaiting', newFile)
          this.postItem(formData, index)
        }
      })
    },
    postItem(formData, index) {
      ClientService.uploadClientCSVFileWithReturningData(this.clientID, formData)
          .then((res) => {
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: 'File uploaded successfully',
              duration: 4000,
              mode: 'success'
            });
            this.$emit('getUploads', true)
            this.filesToUpload = []
            if (this.packageNeeded || this.uploadType === "4506") {
              this.createSubmission(res.data.id)
            }
            this.loading = false
            this.$emit('closeUpload', true)
            // this.awaiting_review[index] = res.data;
            // this.awaiting_review = [...this.awaiting_review]
          }).catch(error => {
        this.$func.popMessage(`${error.response.data.error}`, 'fail')
        this.loading = false
        this.isActiveButton = true
      }).finally(() => {
        this.loading = false
        this.isActiveButton = true
        this.filesToUpload = []
      })
    },
    removeFileFromFileList(index) {
      const dt = new DataTransfer()
      const files = this.$refs.file.files
      this.filesToUpload.splice(index, 1)

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        if (index !== i)
          dt.items.add(file)
      }

      this.$refs.file.files = dt.files
    },
    createSubmission(upload){
      let pkg = this.selectedPackage
      const body = {
          "upload": upload,
          "package": pkg?.id ?? null
        }
        ClientService.uploadClientSubmissionCSVFileFinal(this.clientID,body)
            .then(res => {
            }).catch(error=>{
          this.loadingPostRequest = false
          this.$func.popMessage(`${error.response.data.error}`, 'fail')
        })
    },
    getDefaultPackageByType(type) {
      const packages = this.packagesByType[type];

      if (packages)
        return packages[packages.length - 1];
      else
        return null
    },
    onChange() {
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        if (this.$refs.file.files[i].size < 25000000) {
          let uploadedFile = this.$refs.file.files[i]
          const newFile = {
            original_file_name: uploadedFile.name,
            url: uploadedFile,
            created_on: this.forDate,
            mime_type: uploadedFile.type,
            uploaded_by: this.currentUser.id,
            uploadedFile: uploadedFile,
            uploadingFiles: false,
          }
          if (uploadedFile) {
            this.$emit('fileDropedNotSaved', true)
            this.filesToUpload.push(newFile)
          }
        } else {
          const removeMessageAfter = 2500
          this.errorMessage = true;
          this.startBuffer()
          setTimeout(() => {
            this.errorMessage = false
            clearInterval(this.interval);
            this.errProgressBarValue = 0;
            this.bufferValue = 0;
          }, removeMessageAfter)
        }
      }
    },
  },
  created() {
    this.clientID = this.client
    PackagesService.getPackages(this.clientID).then(
      response => {
        this.packages = response;
        this.packages = this.packages.filter(pkg => pkg.status !== 'draft')
        this.packages.sort((a, b) => a.id - b.id)

        this.packagesByType = this.packages.reduce((acc, obj) => {
          let { status } = obj;
          if (status === 'final')
            status = 'submission'

          if (!acc[status])
            acc[status] = []

          acc[status].push(obj)
          return acc;
        }, {})
        if (this.uploadType){
          this.selectedPackage = this.getDefaultPackageByType(this.uploadType)
        }
      })
  },
  watch: {
    uploadType(val) {
      if (val) {
        this.selectedPackage = this.getDefaultPackageByType(val)

        if (this.packageNeeded) {
          this.isActiveButton = !!this.selectedPackage
        }
        else
          this.isActiveButton = true;
      }
    }
  },
  computed: {
    packageNeeded() {
      return (this.uploadType === 'submission' || this.uploadType === '2020-supplement')
    }
  }
}
</script>


<style scoped>
.textAsVBtn{
  background-color: #263b4f !important;
  border-color: #263b4f !important;
  cursor: pointer;
}
</style>